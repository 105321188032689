<template>
    <div class="ErrAnalysis">
        <div class="flex-jsb">
            <div class="title">
                错题解析（{{ current }}/{{ errList.length }}）
            </div>
            <div class="flex-alc">
                <div
                    v-for="(item, index) in errList"
                    :key="'err' + index"
                    class="num flex-alc center"
                    :class="{ now: index == current - 1 }"
                >
                    {{ index + 1 }}
                </div>
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <div>{{ current }}、题目：{{ subject.questions.title }}（ ）</div>
            <div class="answer">
                <div
                    class="flex-alc mb-16"
                    v-for="(item, name) in subject.questions.type == 2
                        ? [
                              { value: 1, title: '是' },
                              { value: 2, title: '否' },
                          ]
                        : subject.questions.option"
                    :key="'answer' + name"
                >
                    <div v-show="!selfRightKey.includes(name)" class="option">
                        {{
                            subject.questions.type == 2
                                ? ""
                                : selectedType[name]
                        }}
                    </div>
                    <img
                        v-show="selfRightKey.includes(name)"
                        src="@/assets/gou.png"
                        class="select"
                        alt=""
                    />
                    <div class="option-text">{{ item.title }}</div>
                </div>
            </div>
            <div class="correct">
                正确答案：<span
                    v-for="(item, index) in rightKey"
                    :key="'analysis' + index"
                    >{{
                        subject.questions.type == 2
                            ? judge[item]
                            : selectedType[item]
                    }}</span
                >
            </div>
            <div class="analysis">解析：{{ subject.questions.analysis }}</div>
            <div class="operation" v-if="errList.length > 0">
                <div></div>
                <div class="flex">
                    <div
                        class="next click"
                        @click="upSubject"
                        :class="{ last: current == 1 }"
                    >
                        上一题
                    </div>
                    <div
                        class="next click"
                        :class="{ last: current == errList.length }"
                        @click="nextSubject"
                    >
                        下一题
                    </div>
                </div>
                <div class="err back click" @click="back">返回测评中心</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ErrAnalysis",
        data() {
            return {
                errList: [], //错题列表
                selectedType: ["A", "B", "C", "D", "E", "F"], //题目选项
                judge: ["否", "是"], //判断题选项
                current: 1, //当前第几题
                id: 0, //试卷id
                subject: { questions: { title: "", option: [], answer: "" } }, //单个题
            };
        },

        computed: {
            //自己选择的答案
            selfRightKey: function () {
                let index = [];
                if (this.subject.questions.type == 2) {
                    index.push(this.subject.answer == 2 ? 1 : 0);
                } else {
                    let list = this.subject.answer.split(",") || [];
                    this.subject.questions?.option.forEach((i, idx) => {
                        if (list.indexOf(i.value) != -1) {
                            index.push(idx);
                        }
                    });
                }
                return index;
            },
            //正确答案
            rightKey: function () {
                let index = [];
                if (this.subject.questions.type == 2) {
                    index.push(this.subject.questions?.answer == 2 ? 0 : 1);
                } else {
                    let list = this.subject.questions?.answer.split(",") || [];
                    this.subject.questions?.option.forEach((i, idx) => {
                        if (list.indexOf(i.value) != -1) {
                            index.push(idx);
                        }
                    });
                }
                return index;
            },
        },

        mounted() {
            this.id = this.$route.query.paper;
            this.getErr();
        },

        methods: {
            //返回测评中心
            back() {
                this.$router.push({
                    path: "/Evaluation",
                });
            },
            //上一题
            upSubject() {
                if (this.current > 1) {
                    this.current--;
                    this.subject = this.errList[this.current - 1];
                }
            },
            //下一题
            nextSubject() {
                if (this.current < this.errList.length) {
                    this.current++;
                    this.subject = this.errList[this.current - 1];
                }
            },
            //获取错题列表
            getErr() {
                this.$https
                    .get("/api/exam/evaluation/error", { id: this.id, limit: 999 })
                    .then((res) => {
                        this.errList = res.data;
                        this.subject = this.errList[this.current - 1];
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.ErrAnalysis {
    width: 1200px;
    margin: 37px auto 157px auto;
    background: white;
    padding: 18px 26px 31px 34px;
    .title {
        font-size: 18px;
        font-weight: 500;
        color: #2d2d2d;
    }
    .num {
        width: 26px;
        height: 26px;
        border: 1px solid #dfdfdf;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        margin-left: 2px;
    }
    .now {
        background: #f1f1f1;
    }
    .el-divider--horizontal {
        margin-top: 20px;
        margin-bottom: 33px;
    }
    .answer {
        margin-top: 55px;
        .option {
            width: 25px;
            height: 25px;
            background: #f6f6f6;
            border: 1px solid #e1e1e1;
            font-size: 16px;
            font-weight: 400;
            color: #2d2d2d;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
        }
        .select {
            width: 25px;
            height: 25px;
            border-radius: 100%;
        }
        .option-text {
            font-size: 16px;
            font-weight: 400;
            color: #2d2d2d;
            margin-left: 10px;
        }
    }
    .correct {
        font-size: 14px;
        font-weight: 500;
        color: #2d2d2d;
        margin-top: 20px;
        margin-bottom: 7px;
    }
    .analysis {
        font-size: 14px;
        font-weight: 400;
        color: #2d2d2d;
    }
    .operation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 98px;
        margin-bottom: 13px;
        .err {
            width: 120px;
            height: 33px;
            background: linear-gradient(180deg, #ff3764, #ff2604);
            border-radius: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .back {
            background: #949494;
        }
        .next {
            width: 100px;
            height: 33px;
            background: linear-gradient(180deg, #ff3764, #ff2604);
            border-radius: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 21px;
        }
        .last {
            background: #949494;
        }
    }
}
</style>